/* ###### Mapel map  ###### */

.mapael {
  
	.zoomButton {
	  background-color: #292929;
	  border: 1px solid #292929;
	  color: $white;
	  width: 15px;
	  height: 15px;
	  line-height: 12px;
	  text-align: center;
	  border-radius: 3px;
	  cursor: pointer;
	  position: absolute;
	  top: 0;
	  font-weight: bold;
	  left: 10px;
	  -webkit-user-select: none;
	  -khtml-user-select: none;
	  -moz-user-select: none;
	  -o-user-select: none;
	  user-select: none;
	}
  
	.zoomReset {
	  top: 10px;
	}
  
	.zoomIn {
	  top: 30px;
	}
  
	.zoomOut {
	  top: 50px;
	}
  
	.mapTooltip {
	  position: absolute;
	  background-color: #474c4b;
	  opacity: 0.70;
	  filter: alpha(opacity = 70);
	  border-radius: 10px;
	  padding: 10px;
	  z-index: 1000;
	  max-width: 200px;
	  display: none;
	  color: $white;
	}
}
  
.mapcontainer2 .map,
.mapcontainer3 .map {
	height: 300px;
	width: 300px;
	margin: 0 auto;
}
  
.mapcontainer3 svg {
	height: 300px;
}
@media (max-width: 1024px) {
	.mapcontainer.mapael svg,
	.mapcontainer1.mapael svg,
	.mapcontainer2 svg,
	.mapcontainer3 svg {
		width: 250px !important;
	}
}
@media (min-width: 992px) {

	.mapcontainer2 .map {
		width: 300px;
	}
	.mapcontainer3 svg {
		width: 300px;
	}
}
@media (max-width: 991.98px) {
	.mapcontainer .map, .mapcontainer1 .map {
		height: 300px !important;
	}
}

.mapcontainer4, .mapcontainer1, .mapcontainer, .mapcontainer2, .mapcontainer3{
	svg {
		width: 100%;
	}
}
  /* ###### Mapel map  ###### */