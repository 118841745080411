.input-group {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 100%;
  
	> {
	  .form-control, .form-select, .form-control {
		position: relative;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		width: 1%;
		margin-bottom: 0;
	  }
  
	  .form-control:focus, .form-select:focus, .form-control:focus {
		z-index: 3;
		box-shadow: none;
	  }
  
	  .form-control + {
		.form-control, .form-select, .form-control {
		  margin-left: -1px;
		}
	  }
  
	  .form-select + {
		.form-control, .form-select, .form-control {
		  margin-left: -1px;
		}
	  }
  
	  .form-control + {
		.form-control, .form-select, .form-control {
		  margin-left: -1px;
		}
	  }
  
	  .form-control:not(:last-child), .form-select:not(:last-child) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	  }
  
	  .form-control:not(:first-child), .form-select:not(:first-child) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	  }
  
	  .form-control {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
  
		&:not(:last-child) .form-control-label {
		  border-top-right-radius: 0;
		  border-bottom-right-radius: 0;
  
		  &::after {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		  }
		}
  
		&:not(:first-child) .form-control-label {
		  border-top-left-radius: 0;
		  border-bottom-left-radius: 0;
  
		  &::after {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		  }
		}
	  }
	}
  }
  
  .input-group-text, .input-group-text {
	display: -ms-flexbox;
	display: flex;
  }
  
  .input-group-text .btn, .input-group-text .btn {
	position: relative;
	z-index: 2;
  }
  
  .input-group-text {
	.btn + {
	  .btn, .input-group-text {
		margin-left: -1px;
	  }
	}
  
	.input-group-text + {
	  .input-group-text, .btn {
		margin-left: -1px;
	  }
	}
  }
  
  .input-group-text {
	.btn + {
	  .btn, .input-group-text {
		margin-left: -1px;
	  }
	}
  
	.input-group-text + {
	  .input-group-text, .btn {
		margin-left: -1px;
	  }
	}
  }
  
  .input-group-text {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
  }
  
  .input-group-text {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: 0.375rem 0.75rem;
	margin-bottom: 0;
	font-size: 0.9375rem;
	font-weight: 400;
	line-height: 0.9;
	color:$color;
	text-align: center;
	white-space: nowrap;
	background-color: #f4f6fd;
	border: 1px solid rgba(227, 231, 241, 0.8);
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
  
	input {
	  &[type="radio"], &[type="checkbox"] {
		margin-top: 0;
	  }
	}
  }
  
  .input-group > {
	.input-group-text > {
	  .btn, .input-group-text {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	  }
	}
  
	.input-group-text {
	  &:not(:last-child) > {
		.btn, .input-group-text {
		  border-top-right-radius: 0;
		  border-bottom-right-radius: 0;
		}
	  }
  
	  &:last-child > {
		.btn:not(:last-child):not(.dropdown-toggle), .input-group-text:not(:last-child) {
		  border-top-right-radius: 0;
		  border-bottom-right-radius: 0;
		}
	  }
  
	  > {
		.btn, .input-group-text {
		  border-top-left-radius: 0 !important;
		  border-bottom-left-radius: 0 !important;
		}
	  }
	}
  
	.input-group-text {
	  &:not(:first-child) > {
		.btn, .input-group-text {
		  border-top-left-radius: 0 !important;
		  border-bottom-left-radius: 0 !important;
		}
	  }
  
	  &:first-child > {
		.btn:not(:first-child), .input-group-text:not(:first-child) {
		  border-top-left-radius: 0 !important;
		  border-bottom-left-radius: 0 !important;
		}
	  }
	}
  }
  
  .input-group-text > .btn, .input-group-text > .btn, .input-group-btn > .btn {
	height: 100%;
	border-color: rgb(36, 196, 180);
  }
  
  .input-group-text > .input-group-text {
	border-right: 0;
  }
  
  .input-group-text > .input-group-text {
	border-left: 0;
  }