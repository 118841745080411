/*------ Media object ------*/

.media {
	margin-top: 15px;
	zoom: 1;
	overflow: hidden;
}

.media-body {
	zoom: 1;
	overflow: hidden;
	width: auto;
}

.media-object {
	display: block;
}

.media-body,
.media-left,
.media-right {
	display: table-cell;
	vertical-align: top;
}

.media-body h4 {
	font-weight: 500;
	font-size: 16px;
}

.media-body {
	flex: 1;
}