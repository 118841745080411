/*------ Carousel -------*/

.carousel {
	position: relative;
}

.carousel-inner {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 10px;
	left: 0;
	z-index: 1;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	margin-right: 5%;
	margin-left: 5%;
	list-style: none;

	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 9px;
		height: 9px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		border-radius: 50%;
		background-color: $white-5;

		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}

		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}

	.active {
		background-color: $white;
	}
	[data-bs-target] {
		width: 9px;
		height: 9px;
	}
}

.carousel-indicators1 {
	position: absolute;
	right: 0;
	top: 10px;
	left: 0;
	z-index: 1;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	margin-right: 15%;
	margin-left: 15%;
	list-style: none;

	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 9px;
		height: 9px;
		margin-right: 3px;
		margin-left: 3px;
		margin-top: 5px;
		text-indent: -999px;
		background-color: $white-5;
		border-radius: 50%;

		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}

		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}

	.active {
		background-color: $white;
	}
}

.carousel-indicators2 {
	position: absolute;
	right: 10px;
	top: 0;
	left: auto;
	z-index: 1;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	margin-top: 10px;
	list-style: none;

	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 9px;
		height: 9px;
		margin-right: 3px;
		margin-left: 3px;
		margin-top: 5px;
		text-indent: -999px;
		background-color: $white-5;
		border-radius: 50%;

		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}

		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}

	.active {
		background-color: $white;
	}
}

.carousel-indicators3 {
	position: absolute;
	right: auto;
	z-index: 1;
	top: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	margin-top: 10px;
	list-style: none;

	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 9px;
		height: 9px;
		margin-right: 3px;
		margin-left: 3px;
		margin-top: 5px;
		text-indent: -999px;
		background-color: $white-5;
		border-radius: 50%;

		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}

		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}

	.active {
		background-color: $white;
	}
}

.carousel-indicators4 {
	position: absolute;
	right: 10px;
	bottom: 0;
	left: auto;
	z-index: 1;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	margin-top: 10%;
	list-style: none;

	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 9px;
		height: 9px;
		margin-right: 3px;
		margin-left: 3px;
		margin-bottom: 5px;
		text-indent: -999px;
		background-color: $white-5;
		border-radius: 50%;

		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}

		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}

	.active {
		background-color: $white;
	}
}

.carousel-indicators5 {
	position: absolute;
	bottom: 0;
	left: 10px;
	right: auto;
	z-index: 1;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	list-style: none;

	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 9px;
		height: 9px;
		margin-right: 3px;
		margin-left: 3px;
		margin-bottom: 5px;
		text-indent: -999px;
		background-color: $white-5;
		border-radius: 50%;

		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}

		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}

	.active {
		background-color: $white;
	}
}

.carousel-caption {
	position: absolute;
	right: 15%;
	top: 35%;
	left: 15%;
	z-index: 8;
	padding-top: 20px;
	padding-bottom: 20px;
	color: $white;
	text-align: center;
}

.carousel-item-background {
	content: "";
	background: $black-5;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
	height: 1.5rem;
	width: 1.5rem;
}
.product-carousel .carousel-inner .carousel-item {
    padding: 70px;
    border-radius: 5px;
}
.carousel-inner .carousel-item .thumb.active {
    border: 1px solid $border;
}
.carousel-inner .carousel-item .thumb {
    padding: 5px;
    border: 1px solid $border;
    border-radius: 5px;
}
.carousel-item {
	text-align: center;
}
.productdesc-1 {
	border: 1px solid $border;
	border-radius: 5px;
	padding: 1rem;
}
.productdesc-1 {
	.product-carousel-control {
		position: absolute;
		margin: auto;
		height: 30px !important;
		font-size: 5px !important;
		border: 1px solid #403c3c;
		width: 30px;
		background-color: #4e4949;
		border-radius: 5px;
	}
	.carousel-control-next-icon, .carousel-control-prev-icon {
		width: 1rem;
		height: 1rem;
	}
}