/* ########## Contacts ########## */

@media (min-width: 768px) and (max-width: 991.98px) {
    .main-content-body-show {
      .main-header-contacts .main-header-menu-icon {
        display: flex;
      }
  
      .main-content-left-contacts {
        display: block;
      }
    }
  }
  
  .main-content-left-contacts {
    width: 100%;
    display: block;
    padding: 0;
  }

  
	.main-contacts-list {
		height: 531px !important;
		position: relative;
	}
  
  
  .main-contact-label {
    padding-top: 20px;
    padding-bottom: 5px;
    padding-left: 20px;
    font-weight: 500;
    font-size: 12px;
    color: #a8afc7;
    position: relative;
    z-index: 0;
  
    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      border-bottom: 1px solid $border;
      z-index: 5;
    }
  }
  
  .main-contact-item {
    padding: 10px 20px;
    border: 1px solid transparent;
    border-right-width: 0;
    display: flex;
    position: relative;
    cursor: pointer;
  
    + .main-contact-item {
      margin-top: -1px;
      border-top-color: transparent;
      border-top: 1px solid $border;
    }
  
    &:hover, &:focus {
      background-color: $primary-01;
      border-top-color: $border;
      border-bottom-color: $border;
    }
  
    &.selected {
      z-index: 0;
      border-left-color: $border;
      border-top-color: $border;
      border-bottom-color: $border;
      background-color: #f4f6fd;
    }
  
    .main-avatar {
      border-radius: 50%;
    }
  }
  
  @media (min-width: 992px) {
    .main-contact-item {
      padding: 10px 15px;
    }
  }
  
  .main-contact-body {
    flex: 1;
    margin-left: 15px;
  
    h6 {
      color: $color;
      font-weight: 500;
      margin-bottom: 2px;
    }
  
    span {
      font-size: 12px;
      color: #828caa;
    }
  }
  
  .main-contact-star {
    font-size: 16px;
    color: #a8afc7;
  
    &:hover, &:focus {
      color: #a8afc7;
    }
  
    &.active {
      color: $warning;
    }
  }
  
  @media (min-width: 992px) {
    .main-content-body-contacts {
      overflow: hidden;
    }
  }
  
  .main-contact-info-header {
    padding-top: 40px;
    padding-left: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $border;
    position: relative;
  
    .media {
      display: block;
    }
  
    .main-img-user {
      width: 100px;
      height: 100px;
  
      &::after {
        display: none;
      }
  
      a {
        position: absolute;
        bottom: 1px;
        right: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        background-color: #f9f9ff;
        color: #454567;
        font-size: 18px;
        line-height: .9;
        box-shadow: 0 0 0 1px $background;
        border-radius: 50%;
  
        &:hover, &:focus {
          color: $white;
          box-shadow: none;
          background: $primary;
        }
      }
    }
  
    .media-body {
      margin-top: 30px;
  
      h4 {
        color: #454567;
        font-size: 21px;
        font-weight: 400;
        margin-bottom: 2px;
        line-height: 1;
      }
  
      p {
        font-size: 13px;
        color: #3c4858;
        margin-bottom: 15px;
      }
  
      .nav-link {
        padding: 0;
        display: flex;
        align-items: center;
        color: #a8afc7;
        font-size: 13px;
  
        i {
          font-size: 18px;
          line-height: 0;
          margin-right: 5px;
  
          &.typcn {
            line-height: .9;
  
            &::before {
              width: auto;
            }
          }
        }
  
       
  
        + .nav-link {
          margin-left: 10px;
        }
      }
    }
  }
  
  @media (min-width: 992px) {
    .main-contact-info-header .media {
      display: flex;
      align-items: center;
    }
  }
  
  @media (min-width: 992px) {
    .main-contact-info-header .media-body {
      margin-top: 0;
      margin-left: 30px;
    }
  }
  
  @media (min-width: 576px) {
    .main-contact-info-header .media-body .nav-link {
      font-size: 0.875rem;
    }
  }
  
  @media (min-width: 576px) {
    .main-contact-info-header .media-body .nav-link i {
      margin-right: 7px;
    }
  }
  
  @media (min-width: 576px) {
    .main-contact-info-header .media-body .nav-link + .nav-link {
      margin-left: 25px;
    }
  }
  
  .main-contact-action {
    position: absolute;
    top: 0;
    right: 20px;
    display: flex;
  
    a {
      display: flex;
      align-items: center;
      color: #a8afc7;
      font-size: 12px;
    }
  }
  
  @media (min-width: 992px) {
    .main-content-body .main-contact-action {
      right: 0;
    }
  }
  
  .main-contact-info-body {
    padding: 1.25rem;
  
    .media-list {
      padding: 20px 0;
      padding: 15px 0;
    }
  
    .media {
      align-items: center;
      position: relative;
  
      + .media {
        margin-top: 15px;
      }
  
      &:last-child {
        margin-bottom: 15px;
      }
    }
  
    .media-icon {
      font-size: 16px;
      color: $primary;
      line-height: 1;
      width: 35px;
      height: 35px;
      text-align: center;
      border-radius: 50%;
      border: 1px solid $border;
    }
  
    .media-body {
      > div + div {
        margin-top: 15px;
      }
  
      label {
        color: #616c8c;
        margin-bottom: 0;
        display: block;
        font-size: 13px;
      }
  
      span {
        display: block;
        color:  #848eb1;
        font-weight: 400;
      }
    }
  }
  
  @media (min-width: 992px) {
    .main-contact-info-body {
      padding: 1.25rem;
      height: calc(100% - 126px);
      position: relative;
    }
  }
  
  @media (min-width: 992px) {
    .main-contact-info-body .media-body {
      display: flex;
    }
  }
  
  @media (min-width: 992px) {
    .main-contact-info-body .media-body > div {
      flex: 1;
    }
  }
  
  @media (min-width: 992px) {
    .main-contact-info-body .media-body > div + div {
      margin-top: 0;
    }
  }
  .main-avatar {
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-weight: 400;
    font-size: 14px;
}
.main-avatar {
    background-color: $primary;
}
.main-img-user img {
  border-radius: 10px;
}
.main-img-user img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}
img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
}
.main-avatar {
  background-color: $primary;
}
.main-avatar {
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  font-weight: 400;
  font-size: 14px;
}
.main-contact-item.selected {
  z-index: 0;
  border-left-color: #e8e8f7;
  border-top-color: #e8e8f7;
  border-bottom-color: #e8e8f7;
  background-color: var(--primary-transparentcolor);
}
@media (min-width: 992px){
.main-contact-item {
  padding: 10px 15px;
}
}
.main-contact-item {
  padding: 10px 20px;
  border: 1px solid transparent;
  border-right-width: 0;
  display: flex;
  position: relative;
  cursor: pointer;
}
.main-contact-label {
  padding-top: 20px;
  padding-bottom: 5px;
  padding-left: 20px;
  font-weight: 600;
  font-size: 12px;
  color: #a8afc7;
  position: relative;
  z-index: 0;
}
.main-contact-label::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  border-bottom: 1px solid #e8e8f7;
  z-index: 5;
}
.main-avatar.online::after {
  background-color: #19b159;
}
.main-avatar::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 1px;
  width: 7px;
  height: 7px;
  background-color: #a8afc7;
  box-shadow: 0 0 0 2px rgb(255 255 255 / 95%);
  border-radius: 100%;
}
.main-contact-body {
  flex: 1;
  margin-left: 15px;
}
.main-contact-star {
  font-size: 16px;
  color: #a8afc7;
}

.main-content-left-contacts {
  width: 100%;
  display: block;
  padding: 0;
}
.main-content-left {
  width: 100%;
  position: relative;
  padding: 0;
  flex-shrink: 0;
}
.contact-icon {
  display: inline-flex;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 4px;
}

.main-contact-info-header {
  padding-top: 40px;
  padding-left: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8f7;
  position: relative;
}
.main-contact-action {
  position: absolute;
  top: 0;
  right: 20px;
  display: flex;
}
.main-contact-action a {
  display: flex;
  align-items: center;
  color: #a8afc7;
  font-size: 12px;
}
.main-content-body-contacts {
  .main-contact-info-header{
    .btn-list i {
      line-height: 1.5;
      font-size: 18px;
    }
  }
}

.main-contact-info-header .main-img-user {
  width: 100px;
  height: 100px;
}
.main-img-user {
  display: block;
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 100%;
}
.main-contact-info-body .media-icon {
  font-size: 16px;
  color: var(--primary-bg-color);
  line-height: 1;
  width: 35px;
  height: 35px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid $border;
}
.media-logo, .media-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 2px;
}
.main-content-app{
  .main-contact-item{
    .online{
      margin-left: 0;
      margin-top: 0;
    }
  }
}
.contact-icon {
  display: inline-flex;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 0.8rem;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin: 3px;
  background-color: $background;
  color: rgba($color, 0.75);
  border: 1px solid $border;
}

@media (max-width: 375px) {
	.main-contact-body h6 {
    font-size: 12px;
  }
  .main-contact-star {
    font-size: 12px;
  }
}
  
  /* ########## Contacts ########## */