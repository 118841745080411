/*-----progress-----*/

.progress {
	display: -ms-flexbox;
	display: flex;
	height: 1rem;
	overflow: hidden;
	font-size: 0.703125rem;
	background-color: $background;
	border-radius: 3px;
  }
  
  .progress-bar {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	color: $white;
	text-align: center;
	white-space: nowrap;
	transition: width 0.6s ease;
  }
  
  @media screen and (prefers-reduced-motion: reduce) {
	.progress-bar {
	  transition: none;
	}
  }
  
  .progress-bar-striped {
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
	background-size: 1rem 1rem;
  }
  
  .progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
  }
  
  .media {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start;
  }
  
  .media-body {
	-ms-flex: 1;
	flex: 1;
  }
  
  .social {
	i {
	  font-size: 40px;
	}
  
	.social-icon i {
	  font-size: 14px;
	}
  }

  .progress-sm.h-2 .progress-bar {
    height: 0.5rem;
}