/*------ Card -------*/

.card {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: $white;
	border: 1px solid rgba(227, 231, 241, 0.8);
	background-clip: border-box;
	border-radius: 5px;
	box-shadow: $shadow;
  
	> {
	  hr {
		margin-right: 0;
		margin-left: 0;
	  }
	}
  }
  
  .card-body {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 1.5rem;
  }
  
  .card-title {
	margin-bottom: 1.5rem;
  }
  
  .card-subtitle {
	margin-top: -0.75rem;
	margin-bottom: 0;
  }
  
  .card-text:last-child {
	margin-bottom: 0;
  }
  
  .card-link {
	&:hover {
	  text-decoration: none;
	}
  
	+ .card-link {
	  margin-left: 1.5rem;
	}
  }
  
  .card-header {
	padding: 1.5rem 1.5rem;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid $border;
  
	&:first-child {
	  border-radius: calc(3px - 1px) calc(3px - 1px) 0 0;
	}
  
	+ .list-group .list-group-item:first-child {
	  border-top: 0;
	}
  }
  
  .card-footer {
	padding: 1.5rem 1.5rem;
	background-color: rgba(0, 0, 0, 0.03);
	border-top: 1px solid $border;
  }
  
  
  .card-blog-overlay {
	background: url('../images/media/thumbnails/thumb2.jpg');
	background-size: cover;
	position: relative;
	border-radius: 5px !important;
  
	&:before {
	  content: '';
	  display: block;
	  position: absolute;
	  background: $black-5;
	  width: 100%;
	  height: 100%;
	  right: 0;
	  top: 0;
	  border-radius: 5px;
	}
  }
  
  .card-blog-overlay1:before, .card-blog-overlay2:before {
	content: '';
	display: block;
	position: absolute;
	background: $black-5;
	width: 100%;
	height: 100%;
	right: 0;
	top: 0;
	border-radius: 5px;
  }
  
  .card-blog-overlay1 {
	background: url('../images/media/thumbnails/thumb3.jpg');
	background-size: cover;
  }
  
  .card-blog-overlay2 {
	background: url('../images/media/22.jpg');
	background-size: cover;
  }
  
  /*------ Default Card Values -------*/
  
  .card {
	position: relative;
	margin-bottom: 1.5rem;
	width: 100%;
  
	.card {
	  box-shadow: 0px 0px 10px rgba(69, 101, 173, 0.1);
	  border: 1px solid $border !important;
	}
  }
  
  @media print {
	.card {
	  box-shadow: none;
	  border: none;
	}
  }
  
  .card-body {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	margin: 0;
	padding: 1.5rem 1.5rem;
	position: relative;
  
	+ .card-body {
	  border-top: 1px solid $border;
	}
  }
  
  @media print {
	.card-body {
	  padding: 0;
	}
  }
  
  .card-footer, .card-bottom {
	padding: 1rem 1.5rem;
	background: none;
  }
  
  .card-footer {
	border-top: 1px solid $border;
	color: #8499c4;
  }
  
  .card-header {
	background: none;
	padding: 1rem 1.5rem;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
  
	.card-title {
	  margin-bottom: 0;
	}
  
	&.border-0 + .card-body {
	  padding-top: 0;
	}
  }
  
  @media print {
	.card-header {
	  display: none;
	}
  }
  
  
  .card-title {
	font-size: 1rem;
	font-weight: 500;
  
	a {
	  color: inherit;
	}
  
	&:only-child {
	  margin-bottom: 0;
	}
  
	small {
	  color: #8499c4;
	  font-size: 0.875rem;
	  display: block;
	  margin: -.75rem 0 1rem;
	  line-height: 1.1;
	  font-weight: 400;
	}
  }
  
  .card-subtitle {
	color: #8499c4;
	font-size: 0.875rem;
	display: block;
	margin: -.75rem 0 1rem;
	line-height: 1.1;
	font-weight: 400;
  }
  
  .card-table {
	margin-bottom: 0;
  
	tr {
	  &:first-child {
		td, th {
		  border-top: 0;
		}
	  }
  
	  td:first-child, th:first-child {
		padding-left: 1.5rem;
	  }
  
	  td:last-child, th:last-child {
		padding-right: 1.5rem;
	  }
	}
  }
  
  .card-body + .card-table {
	border-top: 1px solid $border;
  }
  
  .card-link + .card-link {
	margin-left: 1rem;
  }
  
  .card-body + .card-list-group {
	border-top: 1px solid $border;
  }
  
  
  .card-aside {
	-ms-flex-direction: row;
	flex-direction: row;
  }
  
  .card-aside-column {
	min-width: 5rem;
	width: 30%;
	-ms-flex: 0 0 30%;
	flex: 0 0 30%;
	background: no-repeat center/cover;
  }
  
  .card-options {
	margin-left: auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-order: 100;
	order: 100;
	margin-right: -.5rem;
	color: #8499c4;
	-ms-flex-item-align: center;
	align-self: center;
  
	a:not(.btn) {
	  margin-left: .5rem;
	  color: #8499c4;
	  display: inline-block;
	  min-width: 1rem;
  
	  &:hover {
		text-decoration: none;
		color: #8499c4;
	  }
  
	  i {
		font-size: 1rem;
		vertical-align: middle;
	  }
	}
  
	.dropdown-toggle:after {
	  display: none;
	}
  }
  
  
  /*Card options*/
  
  .card-collapsed {
	> :not(.card-header):not(.card-status) {
	  display: none;
	}
  
	.card-options-collapse i:before {
	  content: '\e92d';
	}
  }
  
  .card-fullscreen {
	.card-options-fullscreen i:before {
	  content: '\e992';
	}
  
	.card-options-remove {
	  display: none;
	}
  }
  
  /**Card status*/
  
  .card-status {
	position: absolute;
	top: -1px;
	left: 0px;
	right: 0px;
	height: 3px;
	border-radius: 7px 7px 0 0;
  }
  
  .card-status-left {
	right: auto;
	bottom: 0;
	height: auto;
	width: 3px;
	border-radius: 3px 0 0 3px;
  }
  
   /**Card fullscreen*/
  
   .card-fullscreen {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	margin: 0;
  }

  /**Card alert*/
  
  .card-alert {
	border-radius: 0;
	margin: -1px -1px 0;
  }
  
  .card-category {
	font-size: 18px;
	text-transform: uppercase;
	text-align: center;
	font-weight: 500;
	letter-spacing: .05em;
	margin: 0 0 .5rem;
	background: #f4f6fd;
	padding: 8px;
	border-radius: 5px;
  }

  .card .list-group-item {
	  margin-bottom: -1px;
  }

  .card  .example .list-group-item {
	  margin-bottom: 0px;
  }

  hr:not([size]) {
	  height: 0px;
	  border-color: $border; 
  }

.card {
	&.card-collapsed {
		.card-header {
			border-bottom: 0;
		}
	}
}

.card-collapsed > :not(.card-header):not(.card-status) {
	display: none;
}

.card.pos-relative .card-body .text-primary path {
    fill: $primary;
}

.card-table tr:last-child {
	td{
		border-bottom-width: 0;
	}
}