.br-100 {
	border-radius: 100% !important;
  }

  .br-ts-7 {
	border-top-left-radius: 7px !important;
  }
  
  .br-bs-7 {
	border-bottom-left-radius: 7px !important;
  }
  
  .br-te-7 {
	border-top-right-radius: 7px !important;
  }
  
  .br-be-7 {
	border-bottom-right-radius: 7px !important;
  }
  
  .br-ts-0 {
	border-top-left-radius: 0px !important;
  }
  
  .br-bs-0 {
	border-bottom-left-radius: 0px !important;
  }
  
  .br-te-0 {
	border-top-right-radius: 0px !important;
  }

  .br-be-0 {
	border-bottom-right-radius: 0px !important;
  }
  
  .br-0 {
	border-radius: 0 !important;
  }

  .br-3 {
    border-radius: 3px;
    }
  
  
  .br-5 {
	border-radius: 5px;
  }
  
  .br-7 {
	border-radius: 7px;
  }

  .br-ts-4 {
  border-top-left-radius: 4px !important;
  }
  
  .br-bs-4 {
  border-bottom-left-radius: 4px !important;
  }
  
  .br-te-4 {
  border-top-right-radius: 4px !important;
  }
  
  .br-be-4 {
  border-bottom-right-radius: 4px !important;
  }
  
  .br-ts-5 {
	border-top-left-radius: 5px !important;
  }
  
  .br-bs-5 {
	border-bottom-left-radius: 5px !important;
  }
  
  .br-te-5 {
	border-top-right-radius: 5px !important;
  }
  
  .br-be-5 {
	border-bottom-right-radius: 5px !important;
  }

  .br-ts-3 {
  border-top-left-radius: 3px !important;
  }
    
  .br-bs-3 {
  border-bottom-left-radius: 3px !important;
  }
  
  .br-te-3 {
  border-top-right-radius: 3px !important;
  }
  
  .br-be-3 {
  border-bottom-right-radius: 3px !important;
  }
  
  .border {
	border: 1px solid rgba(227, 231, 241, 0.8) !important;
  }
  
  .border-top {
	border-top: 1px solid rgba(227, 231, 241, 0.8) !important;
  }
  
  .border-end {
	border-right: 1px solid rgba(227, 231, 241, 0.8) !important;
  }
  
  .border-bottom {
	border-bottom: 1px solid rgba(227, 231, 241, 0.8) !important;
  }
  
  .border-start {
	border-left: 1px solid rgba(227, 231, 241, 0.8) !important;
  }
  
  .border-0 {
	border: 0 !important;
  }
  
  .border-top-0 {
	border-top: 0 !important;
  }
  
  .border-end-0 {
	border-right: 0 !important;
  }
  
  .border-bottom-0 {
	border-bottom: 0 !important;
  }
  
  .border-start-0 {
	border-left: 0 !important;
  }
  
  .border-bottom-1 {
    border-bottom: 1px;
  }
  
  .border-secondary {
	border-color: $secondary !important;
  }
  
  .border-success {
	border-color: $success !important;
  }
  
  .border-info {
	border-color: $info !important;
  }
  
  .border-warning {
	border-color: #ecb403 !important;
  }
  
  .border-danger {
	border-color: $danger !important;
  }
  
  .border-light {
	border-color: #e5e9f5 !important;
  }
  
  .border-dark {
	border-color: #425e8d !important;
  }
  
  .border-white {
	border-color: $white !important;
  }

  .border-pink {
    border-color: $pink !important;
  }

  .border-orange {
    border-color: $orange !important;
  }
  
  .rounded-top {
	border-top-left-radius: 3px !important;
	border-top-right-radius: 3px !important;
  }
  
  .rounded-right {
	border-top-right-radius: 3px !important;
	border-bottom-right-radius: 3px !important;
  }
  
  .rounded-bottom {
	border-bottom-right-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
  }
  
  .rounded-left {
	border-top-left-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
  }
  
  .rounded-circle {
	border-radius: 50% !important;
  }
  
  .rounded-0 {
	border-radius: 0 !important;
  }

.border-light-primary {
  border-color: $white-1 !important;
}