.select2-container--default {
	.select2-selection--single {
	  background-color: #f4f6fd;
	  border: 1px solid rgba(227, 231, 241, 0.8) !important;
	}
  
	&.select2-container--focus .select2-selection--multiple {
	  background-color: $white;
	  border: 1px solid #d5e4f7;
	  outline: 0;
	  box-shadow: none;
	}
  
	.select2-selection--multiple {
	  background-color: #f4f6fd;
	  border: 1px solid rgba(227, 231, 241, 0.8) !important;
	  cursor: text;
	}
  
	.select2-search--dropdown .select2-search__field {
	  border: 1px solid rgba(227, 231, 241, 0.8) !important;
	}
  
	.select2-selection--multiple {
	  .select2-selection__choice, .select2-selection__choice__remove {
		color: $white !important;
	  }
	}
  
	.select2-results > .select2-results__options {
	  box-shadow: 0 10px 15px rgba(201, 206, 226, 0.4);
	}
  }
  
  .select2-container .select2-selection--single {
	height: 2.375rem !important;
  }
  
  .select2-container--default .select2-selection--single {
	.select2-selection__rendered {
	  color: #444;
	  line-height: 36px !important;
	}
  
	.select2-selection__arrow {
	  height: 2.375rem !important;
	}
  }
  
  .select2-container--focus {
	box-shadow: none !important;
  }
  
  span.select2-selection.select2-selection--single {
	outline: none;
  }

.select2-sm .select2-container .select2-selection--single {
	height: calc(1.5rem + 4px) !important;
	padding-top: 0.391rem;
	padding-bottom: 0.391rem;
	font-size: 0.75rem;
}
.select2-sm .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 13px !important;
}
.select2-sm .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 1.85rem !important;
}
.select2-lg .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 28px !important;
}
.select2-lg .select2-container .select2-selection--single {
    height: calc(2.6975rem + 2px) !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 120%;
}
.select2-lg .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 2.95rem !important;
}
#exampleModal3{
	.select2-container {
		width: 100% !important;
	}
}